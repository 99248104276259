import * as types from './../../mutation-types'
import axios from 'axios'
import i18n from './../../i18n'
import * as Sentry from '@sentry/browser';

import CJSAesJson from "@/vendor/cjsf";
import * as https from "https";

export default {
  state: {
    authenticated: false,
    profile: null,
    loading: false,
    blocked: false,
    maintenance: false
  },
  mutations: {
    [types.SET_AUTH_USER] (state, payload) {
      state.authenticated = true
      state.profile = payload.profile
    },
    [types.UNSET_AUTH_USER] (state, payload) {
      state.authenticated = false
      state.profile = null
    },
    [types.SET_AUTH_LOADING] (state) {
      state.loading = true
    },
    [types.UNSET_AUTH_LOADING] (state) {
      state.loading = false
    },
    SET_BLOCKED (state, isBlocked) {
      state.blocked = isBlocked
    },
    SET_MAINTENANCE (state, isMaintenance) {
      state.maintenance = isMaintenance
    }
  },
  actions: {
    setAuthUser: ({ commit, dispatch }, https) => {
      commit(types.SET_AUTH_LOADING)
      return new Promise((resolve, reject) => {
        let headers = {};
        if(https === true){
          headers = {
            'X-LANG': i18n.locale,
            'Front-End-Https': 'yes'
          }
        }else{
          headers = {
            'X-LANG': i18n.locale
          }
        }
        axios.get(window.ApiRoutes.currentUser,{headers: headers})
          .then(response => {

            const env = process.env.NODE_ENV
            if (env === 'production') {
              // if (response.data){
              //   window.Raven.setExtraContext({ response: response.data })
              // }
            }

            if (response.data.data.code === 401) {
              commit(types.UNSET_AUTH_LOADING)
              reject()
              return false
            }

            let checksum = response.headers['content-checksum'];
            let profile = CJSAesJson.decrypt(response.data.data, checksum);

            if (env === 'production') {
              try {
                // window.Raven.setUserContext({
                //   email: response.data.data.email,
                //   type: 'user'
                // })
                Sentry.setUser({
                  email: profile.email,
                  type: 'user'
                });

              } catch (e) {}
            }
            commit({
              type: types.SET_AUTH_USER,
              profile: profile
            })
            commit(types.UNSET_AUTH_LOADING)
            resolve(response)
          })
          .catch(async (error) => {
            const env = process.env.NODE_ENV
            if (env === 'production') {
              // if (error && error.response && error.response.data){
              //   window.Raven.setExtraContext({ error_response: error.response.data })
              // }
            }

            Sentry.captureException(error);

            if (error.code && error.code >= 400) {
              await dispatch('logoutRequest')
            }
            await commit(types.UNSET_AUTH_LOADING)
            reject(error)
          })
      })
    },
    unsetAuthUser: ({ commit }) => {
      // Update Intercom User data
      // try {
      //   window.Intercom('shutdown')
      //
      //   const env = process.env.NODE_ENV
      //   if (env === 'production') {
      //     window.Intercom('boot', {
      //       app_id: 'uny8bshc'
      //     })
      //   } else {
      //     window.Intercom('boot', {
      //       app_id: 'ji9jgc3i'
      //     })
      //   }
      // } catch (e) {}

      commit({
        type: types.UNSET_AUTH_USER
      })
    },
    setBlocked: ({ commit }, isBlocked) => {
      commit('SET_BLOCKED', isBlocked)
    },
    setMaintenance: ({ commit }, isMaintenance) => {
      commit('SET_MAINTENANCE', isMaintenance)
    }
  }
}
