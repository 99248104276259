import { addClass, removeClass } from '../../helpers/dom'

export default {
  state: {
    open: false
  },
  mutations: {
    changeSidebar (state, payload) {
      state.open = payload.open
    }
  },
  actions: {
    openSidebar ({ commit }) {
      addClass( document.querySelector('body'), 'kt-aside--on')
      const aside = document.querySelector('#kt_aside')
      if (aside) {
        addClass(document.querySelector('#kt_aside'), 'kt-aside--on')
      }
      commit({
        type: 'changeSidebar',
        open: true
      })
    },
    closeSidebar ({ commit }) {
      removeClass(document.querySelector('body'), 'kt-aside--on')
      const aside = document.querySelector('#kt_aside')
      if (aside) {
        removeClass(document.querySelector('#kt_aside'), 'kt-aside--on')
      }
      commit({
        type: 'changeSidebar',
        open: false
      })
    }
  }
}
