// auth
export const SET_AUTH_USER = 'SET_AUTH_USER'
export const UNSET_AUTH_USER = 'UNSET_AUTH_USER'
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING'
export const UNSET_AUTH_LOADING = 'UNSET_AUTH_LOADING'

// login
export const CLEAR_LOGIN_ERRORS = 'CLEAR_LOGIN_ERRORS'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const REQUIRE_MFA = 'REQUIRE_MFA'
export const MESSAGE_ID = 'MESSAGE_ID'

// notification
export const SHOW_SUCCESS_NOTIFICATION = 'SHOW_SUCCESS_NOTIFICATION'
export const HIDE_SUCCESS_NOTIFICATION = 'HIDE_SUCCESS_NOTIFICATION'
export const SHOW_ERROR_NOTIFICATION = 'SHOW_ERROR_NOTIFICATION'
export const HIDE_ERROR_NOTIFICATION = 'HIDE_ERROR_NOTIFICATION'
export const SHOW_INFO_NOTIFICATION = 'SHOW_INFO_NOTIFICATION'
export const HIDE_INFO_NOTIFICATION = 'HIDE_INFO_NOTIFICATION'
export const HIDE_ALL_NOTIFICATION = 'HIDE_ALL_NOTIFICATION'

// edit profile
export const CLEAR_EDIT_PROFILE_ERRORS = 'CLEAR_EDIT_PROFILE_ERRORS'
export const UPDATE_AUTH_USER_NAME = 'UPDATE_AUTH_USER_NAME'
export const UPDATE_AUTH_USER_EMAIL = 'UPDATE_AUTH_USER_EMAIL'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE'

// edit password
export const CLEAR_EDIT_PASSWORD_ERRORS = 'CLEAR_EDIT_PASSWORD_ERRORS'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_FAILURE = 'UPDATE_PASSWORD_FAILURE'
