import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index'
import {siteName} from "../config/api"
import i18n from "./../i18n"
import { supportedLocalesInclude } from "./../util/i18n/supported-locales"
import {updateLocale} from "moment";

const Root = () => import('../components/Root')
// Layouts
const AppLayout = () => import(/* webpackChunkName: "default" */ '../layouts/Default')
const LandingLayout = () => import(/* webpackChunkName: "landing" */ '../layouts/Landing')
const PageLayout = () => import(/* webpackChunkName: "staticPages" */ '../layouts/Page')
const AuditPageLayout = () => import(/* webpackChunkName: "staticPages" */ '../layouts/AuditPage')
const BaseLayout = () => import(/* webpackChunkName: "staticPages" */ '../layouts/Base')

// Errors
const MaintenancePage = () => import(/* webpackChunkName: "staticPages" */ '../pages/errors/MaintenancePage')
const ProfitCreditingPage = () => import(/* webpackChunkName: "staticPages" */ '../pages/errors/ProfitCreditingPage')
const NotFoundPage = () => import(/* webpackChunkName: "staticPages" */ '../pages/NotFoundPage')

// LandingLayout
const LoginPage = () => import(/* webpackChunkName: "landing" */ '../pages/LoginPage')
const LogoutPage = () => import(/* webpackChunkName: "landing" */ '../pages/LogoutPage')
const RegistrationPage = () => import(/* webpackChunkName: "landing" */ '../pages/RegistrationPage')
const ForgotPasswordPage = () => import(/* webpackChunkName: "landing" */ '../pages/ForgotPasswordPage')
const ForgotPasswordConfirmPage = () => import(/* webpackChunkName: "landing" */ '../pages/ForgotPasswordConfirmPage')
const LoginMfaPage = () => import(/* webpackChunkName: "landing" */ '../pages/LoginMfaPage')
const EmailVerifyPage = () => import(/* webpackChunkName: "landing" */ '../pages/EmailVerifyPage')
const EmailUnsubscribePage = () => import(/* webpackChunkName: "landing" */ '../pages/EmailUnsubscribePage')

// AppLayout
const DashboardPage = () => import(/* webpackChunkName: "default" */ '../pages/DashboardPage')
// const AgreementPage = () => import(/* webpackChunkName: "default" */ '../pages/AgreementPage')
const ReportsPage = () => import(/* webpackChunkName: "default" */ '../pages/ReportsPage')
const DepositsPage = () => import(/* webpackChunkName: "default" */ '../pages/DepositsPage')
//const BlogPage = () => import(/* webpackChunkName: "default" */ '../pages/BlogPage')
const MessagesPage = () => import(/* webpackChunkName: "default" */ '../pages/MessagesPage')
const MessagePage = () => import(/* webpackChunkName: "default" */ '../pages/MessagePage')
const AccountPage = () => import(/* webpackChunkName: "default" */ '../pages/AccountPage')
const ProfilePage = () => import(/* webpackChunkName: "default" */ '../pages/ProfilePage')
const PreferencesPage = () => import(/* webpackChunkName: "default" */ '../pages/PreferencesPage')
const MfaPage = () => import(/* webpackChunkName: "default" */ '../pages/MfaPage')
const KycPage = () => import(/* webpackChunkName: "default" */ '../pages/KycPage')
const WithdrawalsPage = () => import(/* webpackChunkName: "default" */ '../pages/WithdrawalsPage')
const DepositBonusPage = () => import(/* webpackChunkName: "default" */ '../pages/DepositBonusPage')
const ReferralsPage = () => import(/* webpackChunkName: "default" */ '../pages/ReferralsPage')
const BlockedPage = () => import(/* webpackChunkName: "default" */ '../pages/BlockedPage')
const FAQPage = () => import(/* webpackChunkName: "default" */ '../pages/FAQPage')
const OTCPage = () => import(/* webpackChunkName: "default" */ '../pages/OTCPage')
const P2PSellsPage = () => import(/* webpackChunkName: "default" */ '../pages/P2PSellsPage')
const NFTPage = () => import(/* webpackChunkName: "default" */ '../pages/NFTPage')
const PerformancePage = () => import(/* webpackChunkName: "default" */ '../pages/PerformancePage')
const AuditsPage = () => import(/* webpackChunkName: "default" */ '../pages/AuditsPage')
const AuditsListPage = () => import(/* webpackChunkName: "default" */ '../pages/audit/AuditsPage')
const AuditFeb2021Page = () => import(/* webpackChunkName: "default" */ '../pages/audit/AuditFeb2021Page')
const AuditFall2021Page = () => import(/* webpackChunkName: "default" */ '../pages/audit/AuditFall2021Page')
const AuditJuly2022Page = () => import(/* webpackChunkName: "default" */ '../pages/audit/AuditJuly2022Page')

// Referral Pages
const ReferralSummaryPage = () => import(/* webpackChunkName: "default" */ '../pages/ReferralSummaryPage')
const ReferralLinkPage = () => import(/* webpackChunkName: "default" */ '../pages/ReferralLinkPage')
const ReferralAnalticsPage = () => import(/* webpackChunkName: "default" */ '../pages/ReferralAnalyticsPage')
const ReferralMaterialsPage = () => import(/* webpackChunkName: "default" */ '../pages/ReferralMaterialsPage')
const ReferralDownlinePage = () => import(/* webpackChunkName: "default" */ '../pages/ReferralDownlinePage')
const ReferralPayitforwardPage = () => import(/* webpackChunkName: "default" */ '../pages/ReferralPayitforwardPage')

// PageLayout
const RiskDisclosure = () => import(/* webpackChunkName: "staticPages" */ '../pages/RiskDisclosure')
const TermsAndConditions = () => import(/* webpackChunkName: "staticPages" */ '../pages/TermsAndConditions')
const PrivacyPolicy = () => import(/* webpackChunkName: "staticPages" */ '../pages/PrivacyPolicy')
const RiskAssessment = () => import(/* webpackChunkName: "staticPages" */ '../pages/RiskAssessment')
const NFTTerms = () => import(/* webpackChunkName: "staticPages" */ '../pages/NFTTerms')
const PublicAuditJuly2022Page = () => import(/* webpackChunkName: "staticPages" */ '../pages/PublicAuditJuly2022Page')
const CoinsAudit = () => import(/* webpackChunkName: "staticPages" */ '../pages/CoinsAuditPage')

const Discord = () => import(/* webpackChunkName: "staticPages" */ '../pages/Discord')

const SubscribeII = () => import(/* webpackChunkName: "staticPages" */ '../pages/SubscribeII')


Vue.use(Router)

const router = new Router({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      let position = {}
      if (to.hash) {
        position = { selector: to.hash }
      } else {
        position = { x: 0, y: 0 }
      }
      return position
    }
  },
  routes: [
    {
      path: '/:locale',
      component: Root,
      children: [
        {
          path: '',
          component: LandingLayout,
          children: [
            {
              path: 'unavailable',
              name: 'blocked',
              component: BlockedPage,
              meta: {
                requiresGuest: true,
                title: 'Unavailable'
              }
            },
            {
              path: '',
              alias: 'login',
              name: 'login',
              component: LoginPage,
              meta: {
                requiresGuest: true,
                title: 'Login'
              }
            },
            {
              path: 'logout',
              name: 'logout',
              component: LogoutPage,
              meta: { requiresAuth: true }
            },
            {
              path: 'register',
              name: 'register',
              component: RegistrationPage,
              meta: {
                requiresGuest: true,
                title: 'Register'
              }
            },
            {
              path: 'forgot-password',
              name: 'forgotPassword',
              component: ForgotPasswordPage,
              meta: {
                requiresGuest: true,
                title: 'Password Reminder'
              }
            },
            {
              path: 'forgot-password/:token/:email',
              name: 'forgotPasswordConfirm',
              component: ForgotPasswordConfirmPage,
              meta: {
                requiresGuest: true,
                title: 'Password Recovery'
              }
            },
            {
              path: 'login/mfa',
              name: 'verifyMfa',
              component: LoginMfaPage,
              meta: {
                requiresGuest: true,
                title: 'Two Factor Authentication'
              }
            },
            {
              path: 'email/verify/:token',
              name: 'email_verify',
              component: EmailVerifyPage,
              meta: {
                title: 'Email Verification'
              }
            }
          ]
        },
        {
          path: '',
          component: AuditPageLayout,
          children: [
            {
              path: 'coins-audit',
              name: 'coinsaudit',
              component: CoinsAudit,
              meta: {
                requiresAuth: true,
                title: 'YieldNodes Coins Audit'
              }
            },
            ]
        },
        {
          path: '',
          component: PageLayout,
          children: [
            /*{
              path: 'auditjuly2022',
              name: 'auditjuly2022',
              component: PublicAuditJuly2022Page,
              meta: {
                title: 'Audit July 2022'
              }
            },*/
            {
              path: 'privacy-policy',
              name: 'privacy',
              component: PrivacyPolicy,
              meta: {
                title: 'Privacy Policy'
              }
            },
            {
              path: 'risk-disclosure',
              name: 'risk',
              component: RiskDisclosure,
              meta: {
                title: 'Risk Disclosure'
              }
            },
            {
              path: 'terms-and-conditions',
              name: 'terms',
              component: TermsAndConditions,
              meta: {
                title: 'Terms And Conditions'
              }
            },
            {
              path: 'risk-assessment',
              name: 'riskassessment',
              component: RiskAssessment,
              meta: {
                title: 'Risk Assessment'
              }
            },
            {
              path: 'nft-terms',
              name: 'nftterms',
              component: NFTTerms,
              meta: {
                title: 'NFT Terms and Conditions'
              }
            },
            {
              path: 'subscribe/ii',
              name: 'subscribe_ii',
              component: SubscribeII,
              meta: {
                title: 'Subscribe'
              }
            },
            {
              path: 'unsubscribe/:token',
              name: 'email_unsubscribe',
              component: EmailUnsubscribePage,
              meta: {
                title: 'Email Unsubscribe'
              }
            },
            {
              path: 'dscrd',
              name: 'discord',
              component: Discord,
              meta: {
                title: 'Risk Assessment'
              }
            }

          ]
        },
        {
          path: '',
          component: AppLayout,
          meta: {
            requiresAuth: true
          },
          children: [
            {
              path: 'dashboard',
              name: 'dashboard',
              component: DashboardPage,
              meta: {
                requiresAuth: true,
                title: 'Dashboard'
              }
            },
            {
              path: 'reports',
              name: 'reports',
              component: ReportsPage,
              meta: {
                requiresAuth: true,
                title: 'Reports'
              }
            },
            {
              path: 'deposit',
              name: 'deposit',
              component: DepositsPage,
              meta: {
                requiresAuth: true,
                title: 'Deposit'
              }
            },
            /*{
              path: 'deposit_buy',
              name: 'deposit_buy',
              component: DepositBonusPage,
              meta: {
                requiresAuth: true,
                title: 'Emergency Withdrawals for Sale'
              }
            },*/
            {
              path: 'withdrawal',
              name: 'withdrawal',
              component: WithdrawalsPage,
              meta: {
                requiresAuth: true,
                title: 'Withdrawal'
              }
            },
            /*{
              path: 'otc',
              name: 'otc',
              component: OTCPage,
              meta: {
                requiresAuth: true,
                title: 'OTC Exchange'
              }
            },
            {
              path: 'marketplace',
              name: 'p2p_sells',
              component: P2PSellsPage,
              meta: {
                requiresAuth: true,
                title: 'Balances marketplace'
              }
            },*/
            {
              path: 'nft',
              name: 'nft',
              component: NFTPage,
              meta: {
                requiresAuth: true,
                title: 'YieldNodes NFT'
              }
            },
            {
              path: 'faq',
              name: 'faq',
              component: FAQPage,
              meta: {
                requiresAuth: true,
                title: 'FAQ'
              }
            },
            /*{
              path: 'performance',
              name: 'performance',
              component: PerformancePage,
              meta: {
                requiresAuth: true,
                title: 'YieldNodes Performance'
              }
            },*/
            /*{
              path: 'audits',
              component: AuditsPage,
              meta: {
                requiresAuth: true,
                title: 'YieldNodes Audit Results'
              },
              children: [
                {
                  path: '',
                  name: 'audits',
                  component: AuditsListPage,
                  meta: {
                    requiresAuth: true,
                    title: 'YieldNodes Audit Results'
                  }
                },
                {
                  path: 'feb2021',
                  name: 'audit_feb2021',
                  component: AuditFeb2021Page,
                  meta: {
                    requiresAuth: true,
                    title: 'YieldNodes Audit - Fall 2021'
                  }
                },
                {
                  path: 'fall2021',
                  name: 'audit_fall2021',
                  component: AuditFall2021Page,
                  meta: {
                    requiresAuth: true,
                    title: 'YieldNodes Audit - Fall 2021'
                  }
                },
                {
                  path: 'july2022',
                  name: 'audit_july2022',
                  component: AuditJuly2022Page,
                  meta: {
                    requiresAuth: true,
                    title: 'YieldNodes Audit - July 2022'
                  }
                },

              ]
            },*/
            {
              path: 'referrals',
              component: ReferralsPage,
              meta: {
                requiresAuth: true,
                title: 'Referrals'
              },
              children: [
                {
                  path: '',
                  name: 'referrals',
                  component: ReferralSummaryPage,
                  meta: {
                    requiresAuth: true,
                    title: 'Referral Summary'
                  }
                },
                {
                  path: 'links',
                  name: 'referralLinks',
                  component: ReferralLinkPage,
                  meta: {
                    requiresAuth: true,
                    title: 'Referral Links'
                  }
                },
                {
                  path: 'analytics',
                  name: 'referralAnalytics',
                  component: ReferralAnalticsPage,
                  meta: {
                    requiresAuth: true,
                    title: 'Referral Analtics'
                  }
                },
                {
                  path: 'materials',
                  name: 'referralMaterials',
                  component: ReferralMaterialsPage,
                  meta: {
                    requiresAuth: true,
                    title: 'Referral Materials'
                  }
                },
                {
                  path: 'downline',
                  name: 'referralDownline',
                  component: ReferralDownlinePage,
                  meta: {
                    requiresAuth: true,
                    title: 'Referral Downline'
                  }
                },
                {
                  path: 'payitforward',
                  name: 'referralPayitforward',
                  component: ReferralPayitforwardPage,
                  meta: {
                    requiresAuth: true,
                    title: 'Pay It Forward'
                  }
                }
              ]
            },
            {
              path: 'messages',
              name: 'messages',
              component: MessagesPage,
              meta: {
                requiresAuth: true,
                title: 'YieldNodes Announcements History'
              }
            },
            {
              path: 'message/:messageId',
              name: 'message',
              component: MessagePage,
              props: true,
              meta: {
                requiresAuth: true,
                title: 'YieldNodes Announcement Message'
              }
            },
            // {
            //   path: '/agreement',
            //   name: 'agreement',
            //   component: AgreementPage,
            //   meta: {
            //     requiresAuth: true,
            //     title: 'Agreement'
            //   }
            // },
            {
              path: 'account',
              component: AccountPage,
              meta: {
                requiresAuth: true,
                title: 'Account Setting'
              },
              children: [
                {
                  path: '',
                  name: 'profile',
                  component: ProfilePage,
                  meta: {
                    requiresAuth: true,
                    title: 'My Account'
                  }
                },
                {
                  path: 'preferences',
                  name: 'preferences',
                  component: PreferencesPage,
                  meta: {
                    requiresAuth: true,
                    title: 'Preferences'
                  }
                },
                {
                  path: '2fa',
                  name: '2fa',
                  component: MfaPage,
                  meta: {
                    requiresAuth: true,
                    title: '2FA Settings'
                  }
                },
                {
                  path: 'kyc',
                  name: 'kyc',
                  component: KycPage,
                  meta: {
                    requiresAuth: true,
                    title: 'KYC Documents'
                  }
                }
              ]
            }
          ]
        },
        {
          path: 'maintenance',
          name: 'maintenance',
          component: MaintenancePage,
          meta: {
            title: 'Service is Under Maintenance'
          }
        },
        {
          path: 'processing',
          name: 'processing',
          component: ProfitCreditingPage,
          meta: {
            title: 'Global calculation is in progress'
          }
        },
        {
          path: '*',
          name: 'not_found',
          component: NotFoundPage,
          meta: {
            title: 'Page Not Found'
          }
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  store.dispatch('hideAllNotifications')
  store.dispatch('closeSidebar')

  if (!store.state.authUser.maintenance && to.name === 'maintenance') {
    next({ name: 'login', params: {locale: i18n.locale} })
    return
  }
  if (store.state.authUser.maintenance && to.name !== 'maintenance') {
    next({ name: 'maintenance', params: {locale: i18n.locale} })
    return
  }

  if (to.name === 'login' && from.meta.requiresAuth) {
    if(from.name !== 'logout' &&
      from.name !== 'dashboard'
    ){
      window.localStorage.setItem('redirect', JSON.stringify({ name: from.name, params: from.params, query: from.query }))
    }
  }

  if (!supportedLocalesInclude(to.params.locale)) {
    return next({ path: i18n.locale + to.path, query: to.query })
  }

  if (from.params.locale !== to.params.locale && supportedLocalesInclude(to.params.locale)) {
    i18n.locale = to.params.locale;
  }
  window.axios.defaults.headers.common['X-LANG'] = i18n.locale

  if (to.meta.title) {
    //document.title = to.meta.title + ' - ' + siteName
    document.title = i18n.t("title." + to.name) + ' - ' + siteName
  } else {
    document.title = siteName
  }
  /*if (to.name === 'agreement' && store.state.authUser.authenticated && store.state.authUser.profile.signed_agreement) {
    next({ name: 'dashboard', params: {locale: i18n.locale} })
  }*/
  if (to.meta.requiresAuth) {
    if (store.state.authUser.authenticated) {
      next()
    } else {
      if(from.name !== 'login') {
        next({name: 'login', params: {locale: i18n.locale}})
      }
      return
    }
  }
  if (to.meta.requiresGuest) {
    if (store.state.authUser.authenticated) {
      if(from.name !== 'dashboard'){
        next({ name: 'dashboard', params: {locale: i18n.locale} })
      }
    }
  }

  next()
})

router.afterEach((to, from) => {
  // if (to.name !== from.name) {
  //   window.Intercom('update')
  // }
})

router.onError(error => {
  if (/loading (CSS )?chunk \S* failed/i.test(error.message)) {
    window.location.reload()
    console.log('Got chunk error. Reloading page')
  } else {
    throw error
  }
})

export default router
