const apiDomain = process.env.VUE_APP_API_DOMAIN || 'https://members.yieldnodes.com/api';
//const apiDomain = process.env.VUE_APP_API_DOMAIN || 'http://members.yield.local:8081/api';
export const siteName = process.env.SITE_NAME || 'Yield Nodes';

export const login = apiDomain + '/user/login'
export const token = apiDomain + '/user/token'
export const loginMfa = apiDomain + '/user/login/mfa_verify'
export const loginMfaResend = apiDomain + '/user/login/mfa_verify/resend'
export const loginMfaEmailResend = apiDomain + '/user/login/mfa_verify/resend_email'
export const loginMfaCall = apiDomain + '/user/login/mfa_verify/call'
export const logout = apiDomain + '/user/logout'
export const switchAccount = apiDomain + '/user/switch_account'
export const passwordReset = apiDomain + '/user/password_reset'
export const checkProcessing = apiDomain + '/user/check_processing'
export const currentUser = apiDomain + '/user/profile_data'
export const updateUserProfile = apiDomain + '/user/profile'
export const updateUserEmergency = apiDomain + '/user/profile/emergency'
export const updateUserPassword = apiDomain + '/user/password'
export const savePhone = apiDomain + '/user/profile/phone'
export const smsSupportedCountries = apiDomain + '/user/profile/sms_supported_countries'
export const appAuthenticatorEnable = apiDomain + '/user/appauth'
export const phoneAuthenticatorEnable = apiDomain + '/user/phoneauth'
export const mfa = apiDomain + '/user/mfa'
export const mfaPhone = apiDomain + '/user/mfa_phone'
export const mfaEmail = apiDomain + '/user/mfa_email'
export const mfaAction = apiDomain + '/user/mfa/action'
export const resendEmail = apiDomain + '/user/email/resend'
export const verifyEmail = apiDomain + '/user/email/verify'
export const unsubscribeEmail = apiDomain + '/unsubscribe'
export const kycFiles = apiDomain + '/user/kyc'
export const depositVoucher = apiDomain + '/user/deposit/voucher'
export const depositWallet = apiDomain + '/user/deposit/wallet'
export const depositWalletExtend = apiDomain + '/user/deposit/wallet/extend'
export const depositApiWireDetails = apiDomain + '/user/deposit/api_wire_details'
export const deposit = apiDomain + '/user/deposit'
export const depositWithdraw = apiDomain + '/user/deposit/release'
export const profitReinvest = apiDomain + '/user/profit/reinvest'
export const profitAutomaticReinvest = apiDomain + '/user/profit/automatic_reinvest'
export const profitBalance = apiDomain + '/user/profit/balance'
export const donate = apiDomain + '/user/profit/donate'
export const depositReleasedBalance = apiDomain + '/user/deposit/released'
export const withdrawWallet = apiDomain + '/user/withdraw/wallet'
export const withdrawWalletRemove = apiDomain + '/user/withdraw/wallet/delete'
export const withdrawBankDetails = apiDomain + '/user/withdraw/bank'
export const withdraw = apiDomain + '/user/withdraw'
export const emergencyWithdrawBalances = apiDomain + '/user/emergency_withdraw/balances'
export const emergencyWithdraw = apiDomain + '/user/emergency_withdraw'
export const depositBonus = apiDomain + '/user/deposit_bonus'
export const phoneMessageStatus = apiDomain + '/user/phoneauth/status'
export const reports = apiDomain + '/user/report'
export const refresh = apiDomain + '/user/refresh'
export const blog = apiDomain + '/user/blog'
export const help = apiDomain + '/user/help'
export const notifications = apiDomain + '/user/notifications'
export const sessions = apiDomain + '/user/sessions'
export const privacy = apiDomain + '/user/privacy_settings'
export const agreement = apiDomain + '/user/agreement'
export const register = apiDomain + '/user/register'
export const affiliate = apiDomain + '/user/affiliate'
export const countries = apiDomain + '/countries'
export const tutorials = apiDomain + '/tutorials'
export const statements = apiDomain + '/user/statements'
export const estimateProfits = apiDomain + '/user/estimate_profits'
export const fund_stats = apiDomain + '/fund_stats'
export const fund_performance = apiDomain + '/fund_performance'
export const feedback = apiDomain + '/feedback'
export const messages = apiDomain + '/messages'
export const subscribe_ii = apiDomain + '/subscribe/ii'
export const createAuditRequest = apiDomain + '/user/create_audit_request'
export const otc = apiDomain + '/user/otc'
export const discord = apiDomain + '/discord'
export const poll = apiDomain + '/user/poll'
export const p2p_sell = apiDomain + '/user/p2p_sell'
export const audits = apiDomain + '/audits'
