import axios from 'axios';
import * as Sentry from '@sentry/browser';
import * as types from './../../mutation-types'
import i18n from './../../i18n'
import router from "@/router";

export default {
  state: {
    errors: {
      email: null,
      password: null
    },
    mfa_method: null,
    message_id: null,
    debug_code: null
  },
  mutations: {
    [types.LOGIN_SUCCESS] (state, payload) {
      state.errors.email = null
      state.errors.password = null
    },
    [types.LOGIN_FAILURE] (state, payload) {
      state.errors.email = payload.errors.email ? payload.errors.email[0] : null
      state.errors.password = payload.errors.password ? payload.errors.password[0] : null
    },
    [types.CLEAR_LOGIN_ERRORS] (state, payload) {
      state.errors.email = null
      state.errors.password = null
    },
    [types.REQUIRE_MFA] (state, payload) {
      state.mfa_method = payload.mfa_method
    },
    [types.MESSAGE_ID] (state, payload) {
      state.message_id = payload.message_id
    },
    DEBUG_CODE (state, payload) {
      state.debug_code = payload.debug_code
    }
  },
  actions: {
    loginRequest: ({ dispatch }, formData) => {
      return new Promise((resolve, reject) => {
        axios.post(window.ApiRoutes.login, formData)
          .then(async (response) => {
            let userLocale;
            if (response.data && response.data.data.status && response.data.data.status === 'requires_mfa') {
              if(response.data.data.locale) userLocale = response.data.data.locale;
              await dispatch('requireMfa', { 'mfa_method': response.data.data.method, locale: userLocale })
              if (response.data.data.message_id) {
                await dispatch('setMessageId', { 'message_id': response.data.data.message_id })
                await dispatch('setCode', { 'debug_code': response.data.data.debug_code })
              }
              resolve({ response: 'requires_mfa', locale: userLocale})
            } else {
              if(response.data.data.locale) userLocale = response.data.data.locale;
              dispatch('loginSuccess', response.data).then(() => {
                resolve({ response: response, locale: userLocale})
              }).catch(error => {
                Sentry.captureException(error);
              })
            }
          })
          .catch(async (error) => {
            const data = error.response ? (error.response.data || null) : null;
            const response = error.response;
            await dispatch('loginFailure', response)
            reject(error)
          })
      })
    },
    loginSuccess: ({ commit, dispatch }, data) => {
      commit({
        type: types.LOGIN_SUCCESS
      })
      return dispatch('setAuthUser')
    },
    loginFailure: async ({ commit, dispatch }, response) => {

      const body = response ? (response.data || null) : null;
      const code = response ? (response.status || null) : null;

      await commit({
        type: types.LOGIN_FAILURE,
        errors: body ? (body.errors || []) : []
      })

      if (code === 429){
        if(body.data && body.data.message){
          return dispatch('showErrorNotification', body.data.message)
        }else{
          return dispatch('showErrorNotification', i18n.t('login.failed_too_many'))
        }
      }
      if(code === 529){
        await router.push({name: 'processing'});
        return dispatch('showErrorNotification', 'Global calculation is in progress. Please, wait several hours until global calculation is in progress. Once it\'s over - you would be able to login again.');
      }
      if (code === 422){
        if (body.message)
          return dispatch('showErrorNotification', body.message);
      } else {
        if (body && body.data && body.data.message) {
          return dispatch('showErrorNotification', body.data.message)
        } else {
          return dispatch('showErrorNotification', i18n.t('login.failed'))
        }
      }
    },
    clearLoginErrors: ({ commit }) => {
      commit({
        type: types.CLEAR_LOGIN_ERRORS
      })
    },
    requireMfa: ({ commit }, body) => {
      commit({
        type: types.REQUIRE_MFA,
        mfa_method: body.mfa_method,
        locale: body.locale
      })
    },
    setMessageId: ({ commit }, body) => {
      commit({
        type: types.MESSAGE_ID,
        message_id: body.message_id
      })
    },
    setCode: ({ commit }, body) => {
      commit({
        type: 'DEBUG_CODE',
        debug_code: body.debug_code
      })
    },
    logoutRequest: ({ dispatch }) => {
      return new Promise((resolve, reject) => {
        axios.post(window.ApiRoutes.logout, {})
          .then(response => {
            dispatch('unsetAuthUser')
            resolve()
          })
          .catch(error => {
            dispatch('showErrorNotification', i18n.t('login.failed'))
            reject()
          })
      })
    }
  }
}
