import axios from 'axios'
import * as Sentry from '@sentry/browser';
import router from './router'
import store from './store/index'
import i18n from './i18n'

import Raphael from 'raphael/raphael'
global.Raphael = Raphael

window.axios = axios
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

// window.$ = window.jQuery = jquery
// require('chosen-js')
// require('chosen-js/chosen.css')

// let token = document.head.querySelector('meta[name="csrf-token"]')
//
// if (token) {
//   window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content
// } else {
//   console.error('CSRF token not found')
// }

window.axios.interceptors.response.use((response) => {
  return response
}, error => {
  // if (error.response.status === 401) {
  //   store.dispatch('logoutRequest')
  //     .then(()=> {
  //       router.push({name: 'login'});
  //     });
  // }

  if (error.response.status === 451) {
    store.dispatch('setBlocked', true)
    //router.push({name: 'blocked'})
  }

  if (error.response.status === 503) {
    store.dispatch('setMaintenance', true)
    //router.push({ name: 'maintenance', params: {locale: i18n.locale} });
  }

  return Promise.reject(error)
})
