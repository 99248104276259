<template>
  <div style="flex: 1;display: flex;flex-direction: row;">
    <router-view :key="$route.fullPath" />
    <NprogressContainer />
    <PortalTarget name="modals" />
    <PortalTarget name="subModals" />

    <!--<tour-container :steps="tourSteps" name="initialTour"></tour-container>-->
    <!--<a href="#" @click.prevent="$tours.testTour.start()">Start Tour</a>-->
  </div>
</template>

<script>
import axios from 'axios';
import NprogressContainer from 'vue-nprogress/src/NprogressContainer'
import i18n from './i18n';
import * as Sentry from '@sentry/browser';
// import TourContainer from './components/Tour/Container'
// import tourData from './tour'

export default {
  name: 'App',
  components: {
    NprogressContainer
    // TourContainer
  },
  data () {
    return {
      // tourSteps: tourData
    }
  },
  beforeCreate () {
    window.axios.interceptors.response.use(response => {
      return response
    }, error => {
      if (error.response && error.message && error.message.includes('Unexpected token')) {
        Sentry.setContext('request', {
          status: error.response.status,
          data: error.response.data
        });
      }
      // let errorResponseData = error.response.data
      if (error.response && error.response.status === 401 && this.$store.state.authUser.authenticated) {
        let isDemo = this.$store.state.authUser.profile.is_demo
        this.$store.dispatch('unsetAuthUser').then(() => {
          if (isDemo) {
            this.$store.dispatch('showErrorNotification', 'Please register an account')
          } else {
            this.$store.dispatch('showErrorNotification', this.$t('login.session_expired'))
          }
        }).then(() => {
          return axios.get(this.$api.token)
        }).then(response => {
          window.axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data.data.token
          if (isDemo) {
            this.$router.push({ name: 'register', params: {locale: i18n.locale} })
          } else {
            this.$router.push({ name: 'login', params: {locale: i18n.locale} })
          }
        }).catch(error => {
          this.$store.dispatch('showErrorNotification', this.$t('login.session_expired_refresh'))
        })
      }

      if(error.response && error.response.status === 521) {
        this.$store.dispatch('showErrorNotification', 'Operation failed! Server is currently offline or in maintenance. Please, be patient, maintenance team has been notified! Reload the page in 10 minutes to check if it\'s back online.')
      }

      if(error.response && error.response.status === 530) {
        this.$store.dispatch('showErrorNotification', 'Error: Access Blocked; \n' +
          'Reason: Security Breach Detected; \n' +
          'Description: You are attempting to access the website with a data-breaching browser extension; \n' +
          'Resolution: Please contact support for assistance.');
      }

      if (error.response && error.response.status === 419) {
        return new Promise((resolve, reject) => {
          axios.get(this.$api.token).then(response => {
            window.axios.defaults.headers.common['X-CSRF-TOKEN'] = response.data.data.token
            error.config.headers['X-CSRF-TOKEN'] = response.data.data.token
            resolve(axios.request(error.config))
          }).catch(subError => {
            reject(subError)
          })
        })
      }

      return Promise.reject(error)
    })
  },
  created () {

  }
}
</script>

<style lang="scss">
  @import '../sass/style.scss';
</style>
