// IE8+

export const addClass = (el, className) => {
  if (el.classList) {
    el.classList.add(className)
  } else {
    el.className += ' ' + className
  }
}

export const removeClass = (el, className) => {
  if (el.classList) {
    el.classList.remove(className)
  } else {
    el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
  }
}

export const hasClass = (el, className) => {
  if (el.classList) { return el.classList.contains(className) } else { return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className) }
}

export const toggleClass = (el, className) => {
  hasClass(el, className) ? removeClass(el, className) : addClass(el, className)
}

export const scrollTop = () => {
  return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
}

export const changeCSS = (id, cssFile) => {
  let oldLink = document.getElementById(id)

  if (oldLink.getAttribute('href') === cssFile) {
    return
  }

  let newLink = document.createElement('link')
  newLink.setAttribute('rel', 'stylesheet')
  newLink.setAttribute('type', 'text/css')
  newLink.setAttribute('href', cssFile)
  newLink.setAttribute('id', id)

  document.getElementsByTagName('head').item(0).replaceChild(newLink, oldLink)
}
