import vue from 'vue'

import BootstrapVue from 'bootstrap-vue'

import NProgress from 'vue-nprogress'

import VueNotifications from 'vue-notifications'
import Noty from 'noty'

import VueScrollTo from 'vue-scrollto'

import MaskedInput from 'vue-text-mask'

import Vue2Filters from 'vue2-filters'

import Paginate from 'vuejs-paginate'

import vSelect from 'vue-select'

import PortalVue from 'portal-vue'

// import Raven from 'raven-js'
// import RavenVue from 'raven-js/plugins/vue'
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
//import OneSignalVue from 'onesignal-vue'

export const Vue = window.Vue = vue
Vue.use(BootstrapVue)

const progressOptions = {
  latencyThreshold: 0, // Number of ms before progressbar starts showing, default: 100,
  router: true, // Show progressbar when navigating routes, default: true
  http: false // Show progressbar when doing Vue.http, default: true
}

Vue.use(NProgress, progressOptions)

export const nprogress = new NProgress({ showSpinner: false })
// https://github.com/needim/noty
// import 'noty/lib/noty.css'

const toast = ({ title, message, type, timeout, cb }) => {
  if (type === VueNotifications.types.warn) {
    type = 'warning'
  }
  return new Noty({
    text: message,
    timeout,
    type,
    layout: 'bottomRight',
    theme: 'bootstrap-v4',
    progressBar: false,
    closeWith: 'button'
  }).show()
}

const notificationOptions = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  timeout: 3000
}

Vue.use(VueNotifications, notificationOptions)
Vue.use(VueScrollTo)
Vue.use(PortalVue)
Vue.component('MaskedInput', MaskedInput)
Vue.use(Vue2Filters)
Vue.component('paginate', Paginate)
Vue.component('v-select', vSelect)
//Vue.use(OneSignalVue);
const env = process.env.NODE_ENV
if (env === 'production') {
  // window.Raven = Raven
  //   .config('https://b691959f9c7e41fe8d6f2861f7850926@a.gfvsky.digital/3')
  //   .addPlugin(RavenVue, Vue)
  //   .install()
  Sentry.init({
    dsn: 'https://760af1d727f946408e52a0c9e06b22c4@o503222.ingest.sentry.io/5588591',
    autoSessionTracking: true,
    integrations: [
      new VueIntegration({
        Vue,
        attachProps: true,
        logErrors: true
      })
    ]
  })
}

Vue.prototype.$tours = {}
