import 'core-js/stable'
import 'regenerator-runtime/runtime'

import './bootstrap'
import { Vue, nprogress } from './vue_modules'

import router from './router'
import store from './store'
import App from './App'

import * as api from './config/api'
import i18n from './i18n'

window.ApiRoutes = Vue.prototype.$api = api

Vue.prototype.Trustpilot = window.Trustpilot

store.dispatch('setAuthUser').then(() => initializeApp()).catch(() => initializeApp())

function initializeApp () {
  const main = new Vue({
    el: '#app',
    nprogress,
    router,
    store,
    beforeMount() {
      /*this.$OneSignal.init({
        appId: '51e3a867-1974-4fa5-9632-e414af2c0ffc', // sandbox: 409df9ac-0e97-4c9c-975d-229988d4e961
        //appId: '409df9ac-0e97-4c9c-975d-229988d4e961',
        safari_web_id: "web.onesignal.auto.458f9dc8-6677-4788-b5a2-9d66a9d7a179", // sandbox: web.onesignal.auto.1f1e5b5b-9f41-4253-8171-f20d7e1a840b
        //safari_web_id: "web.onesignal.auto.1f1e5b5b-9f41-4253-8171-f20d7e1a840b",
        //subdomainName: "localhost:8081",
        //allowLocalhostAsSecureOrigin: true,
        autoResubscribe: true
      });*/
    },
    mounted () {
      // let authed = false
      // let profile = {}
      // let intercomData = {}
      // if (this.$store.state.authUser.authenticated) {
      //   authed = true
      //   profile = this.$store.state.authUser.profile
      // }
      //
      // if (authed) {
      //   intercomData = {
      //     email: profile.email,
      //     // user_id: profile.id,
      //     name: profile.first_name + ' ' + profile.last_name
      //   }
      // }
      //
      // const env = process.env.NODE_ENV
      // if (env === 'production') {
      //   window.Intercom('boot', {
      //     app_id: 'uny8bshc',
      //     ...intercomData
      //   })
      // } else {
      //   window.Intercom('boot', {
      //     app_id: 'ji9jgc3i',ы
      //     ...intercomData
      //   })
      // }
    },

    i18n,
    render: h => h(App)
  })
  window.app = main


}
